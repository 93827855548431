<template>
  <div id="app">
    <van-nav-bar :title="`视频群聊 - ${browserInfo}`" />
    
    <div class="video-container">
      <div class="video-wrapper local-video">
        <video ref="localVideo" autoplay muted playsinline></video>
        <van-tag type="primary" class="name-tag">我</van-tag>
      </div>
      <div v-for="peer in peers" :key="peer.id" class="video-wrapper">
        <video :ref="`remoteVideo_${peer.id}`" autoplay playsinline></video>
        <van-tag type="success" class="name-tag">{{ peer.displayName }}</van-tag>
      </div>
    </div>

    <van-cell-group>
      <van-field v-model="roomId" placeholder="输入房间ID" />
      <van-button type="primary" @click="joinRoom" :loading="isJoining">加入房间</van-button>
      <van-button type="danger" @click="leaveRoom" :disabled="!isJoined">离开房间</van-button>
      <van-button type="primary" @click="toggleVideo" :disabled="!isJoined">{{ isVideoOn ? '关闭视频' : '开启视频' }}</van-button>
      <van-button type="primary" @click="toggleAudio" :disabled="!isJoined">{{ isAudioOn ? '关闭麦克风' : '开启麦克风' }}</van-button>
    </van-cell-group>

    <van-collapse v-model="activeNames">
      <van-collapse-item title="本地状态" name="1">
        <pre>{{ JSON.stringify(localState, null, 2) }}</pre>
      </van-collapse-item>
      <van-collapse-item title="远程状态" name="2">
        <pre>{{ JSON.stringify(remoteState, null, 2) }}</pre>
      </van-collapse-item>
    </van-collapse>

    <Stats v-if="showStats" :roomClient="roomClient" :isMe="true" @close="showStats = false" />
  </div>
</template>

<script>
import RoomClient from './lib/RoomClient';
import Stats from './components/Stats.vue';
import deviceInfo from './deviceInfo';
import { Toast } from 'vant';

export default {
  name: 'App',
  components: {
    Stats
  },
  data() {
    return {
      roomClient: null,
      roomId: '',
      peers: [],
      isVideoOn: false,
      isAudioOn: false,
      browserInfo: '',
      isJoining: false,
      isJoined: false,
      showStats: false,
      activeNames: ['1', '2'],
      localState: {
        peerId: null,
        displayName: null,
        device: null,
        rtpCapabilities: null,
        sctpCapabilities: null,
        transports: {
          send: null,
          recv: null
        },
        producers: [],
        consumers: [],
        dataProducers: [],
        dataConsumers: []
      },
      remoteState: {
        peers: []
      }
    };
  },
  mounted() {
    this.getBrowserInfo();
  },
  methods: {
    async joinRoom() {
      if (this.isJoining) return;
      this.isJoining = true;
      try {
        this.roomClient = new RoomClient({
          roomId: this.roomId,
          peerId: `user-${Math.random().toString(36).substr(2, 9)}`,
          displayName: `User_${Math.floor(Math.random() * 1000)}`,
          device: deviceInfo(),
          handlerName: 'Chrome74',
          produce: true,
          consume: true
        });

        this.roomClient.on('roomJoined', ({ peers }) => {
          this.peers = peers;
          this.isJoined = true;
          Toast.success('加入房间成功');
          this.updateLocalState();
          this.updateRemoteState();
        });

        this.roomClient.on('newPeer', ({ peer }) => {
          this.peers.push(peer);
          this.updateRemoteState();
        });

        this.roomClient.on('peerClosed', ({ peerId }) => {
          this.peers = this.peers.filter(p => p.id !== peerId);
          this.updateRemoteState();
        });

        this.roomClient.on('producerStateChanged', () => {
          this.updateLocalState();
        });

        this.roomClient.on('consumerStateChanged', () => {
          this.updateLocalState();
        });

        await this.roomClient.join();
        this.isVideoOn = true;
        this.isAudioOn = true;
      } catch (error) {
        console.error('Failed to join room:', error);
        Toast.fail(`加入房间失败: ${error.message}`);
      } finally {
        this.isJoining = false;
      }
    },
    async leaveRoom() {
      if (this.roomClient) {
        await this.roomClient.close();
        this.roomClient = null;
        this.peers = [];
        this.isVideoOn = false;
        this.isAudioOn = false;
        this.isJoined = false;
        this.localState = {
          peerId: null,
          displayName: null,
          device: null,
          rtpCapabilities: null,
          sctpCapabilities: null,
          transports: {
            send: null,
            recv: null
          },
          producers: [],
          consumers: [],
          dataProducers: [],
          dataConsumers: []
        };
        this.remoteState = {
          peers: []
        };
        Toast.success('已离开房间');
      }
    },
    async toggleVideo() {
      if (this.isVideoOn) {
        await this.roomClient.disableWebcam();
        this.isVideoOn = false;
      } else {
        await this.roomClient.enableWebcam();
        this.isVideoOn = true;
      }
      this.updateLocalState();
    },
    async toggleAudio() {
      if (this.isAudioOn) {
        await this.roomClient.disableMic();
        this.isAudioOn = false;
      } else {
        await this.roomClient.enableMic();
        this.isAudioOn = true;
      }
      this.updateLocalState();
    },
    getBrowserInfo() {
      const info = deviceInfo();
      this.browserInfo = `${info.name} ${info.version}`;
    },
    updateLocalState() {
      if (this.roomClient) {
        this.localState = {
          peerId: this.roomClient._peerId,
          displayName: this.roomClient._displayName,
          device: this.roomClient._device,
          rtpCapabilities: this.roomClient._mediasoupDevice ? this.roomClient._mediasoupDevice.rtpCapabilities : null,
          sctpCapabilities: this.roomClient._mediasoupDevice ? this.roomClient._mediasoupDevice.sctpCapabilities : null,
          transports: {
            send: this.roomClient._sendTransport ? {
              id: this.roomClient._sendTransport.id,
              connectionState: this.roomClient._sendTransport.connectionState
            } : null,
            recv: this.roomClient._recvTransport ? {
              id: this.roomClient._recvTransport.id,
              connectionState: this.roomClient._recvTransport.connectionState
            } : null
          },
          producers: this.roomClient._producers ? Array.from(this.roomClient._producers.values()).map(p => ({
            id: p.id,
            kind: p.kind,
            paused: p.paused,
            track: p.track ? {
              enabled: p.track.enabled,
              readyState: p.track.readyState
            } : null
          })) : [],
          consumers: this.roomClient._consumers ? Array.from(this.roomClient._consumers.values()).map(c => ({
            id: c.id,
            kind: c.kind,
            paused: c.paused,
            producerPaused: c.producerPaused,
            track: c.track ? {
              enabled: c.track.enabled,
              readyState: c.track.readyState
            } : null
          })) : [],
          dataProducers: this.roomClient._dataProducers ? Array.from(this.roomClient._dataProducers.values()).map(dp => ({
            id: dp.id,
            label: dp.label,
            protocol: dp.protocol
          })) : [],
          dataConsumers: this.roomClient._dataConsumers ? Array.from(this.roomClient._dataConsumers.values()).map(dc => ({
            id: dc.id,
            label: dc.label,
            protocol: dc.protocol
          })) : []
        };
      }
    },
    updateRemoteState() {
      this.remoteState = {
        peers: this.peers.map(peer => ({
          id: peer.id,
          displayName: peer.displayName,
          device: peer.device,
          consumers: peer.consumers ? peer.consumers.map(c => ({
            id: c.id,
            kind: c.kind,
            paused: c.paused,
            producerPaused: c.producerPaused
          })) : [],
          dataConsumers: peer.dataConsumers ? peer.dataConsumers.map(dc => ({
            id: dc.id,
            label: dc.label,
            protocol: dc.protocol
          })) : []
        }))
      };
    }
  }
};
</script>

<style>
.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.video-wrapper {
  position: relative;
  width: 320px;
  height: 240px;
  margin: 10px;
  border: 1px solid #ccc;
}
.video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.name-tag {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>