<template>
  <div data-component="Stats">
    <div class="content" :class="{ visible: !closed }">
      <div class="header">
        <div class="info">
          <van-icon name="cross" @click="handleClose" />
          <h1>{{ title }}</h1>
        </div>

        <div class="list">
          <template v-if="stats.transport">
            <p>
              transport stats:
              <a href="#transport-remote-stats">[remote]</a>
              <span> </span>
              <a href="#transport-local-stats">[local]</a>
            </p>
          </template>

          <template v-if="stats.producer">
            <p>
              producer stats:
              <a href="#producer-remote-stats">[remote]</a>
              <span> </span>
              <a href="#producer-local-stats">[local]</a>
            </p>
          </template>

          <template v-if="stats.consumer">
            <p>
              consumer stats:
              <a href="#consumer-remote-stats">[remote]</a>
              <span> </span>
              <a href="#consumer-local-stats">[local]</a>
            </p>
          </template>

          <template v-if="stats.dataProducer">
            <p>
              dataProducer stats:
              <a href="#dataproducer-remote-stats">[remote]</a>
            </p>
          </template>

          <template v-if="stats.dataConsumer">
            <p>
              dataConsumer stats:
              <a href="#dataconsumer-remote-stats">[remote]</a>
            </p>
          </template>
        </div>
      </div>

      <div class="stats">
        <template v-if="stats.transport">
          <div class="items">
            <h2 id="transport-remote-stats">transport remote stats</h2>
            <stats-parser :data="stats.transport.remote" />
          </div>

          <div class="items">
            <h2 id="transport-local-stats">transport local stats</h2>
            <stats-parser :data="stats.transport.local" />
          </div>
        </template>

        <template v-if="stats.producer">
          <div class="items">
            <h2 id="producer-remote-stats">producer remote stats</h2>
            <stats-parser :data="stats.producer.remote" />
          </div>

          <div class="items">
            <h2 id="producer-local-stats">producer local stats</h2>
            <stats-parser :data="stats.producer.local" />
          </div>
        </template>

        <template v-if="stats.consumer">
          <div class="items">
            <h2 id="consumer-remote-stats">consumer remote stats</h2>
            <stats-parser :data="stats.consumer.remote" />
          </div>

          <div class="items">
            <h2 id="consumer-local-stats">consumer local stats</h2>
            <stats-parser :data="stats.consumer.local" />
          </div>
        </template>

        <template v-if="stats.dataProducer">
          <div class="items">
            <h2 id="dataproducer-remote-stats">dataProducer remote stats</h2>
            <stats-parser :data="stats.dataProducer.remote" />
          </div>
        </template>

        <template v-if="stats.dataConsumer">
          <div class="items">
            <h2 id="dataconsumer-remote-stats">dataConsumer remote stats</h2>
            <stats-parser :data="stats.dataConsumer.remote" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant';
import StatsParser from './StatsParser.vue';

export default {
  name: 'Stats',
  components: {
    [Icon.name]: Icon,
    StatsParser
  },
  props: {
    isMe: Boolean,
    peerName: String,
    roomClient: Object,
    producerId: String,
    audioConsumerId: String,
    videoConsumerId: String,
    dataConsumerId: String
  },
  data() {
    return {
      closed: false,
      stats: {}
    };
  },
  computed: {
    title() {
      return this.isMe ? 'Your Stats' : `Stats of ${this.peerName}`;
    }
  },
  methods: {
    handleClose() {
      this.closed = true;
      clearInterval(this.timer);
      this.$emit('close');
    },
    async getStats() {
      if (this.isMe) {
        this.stats = {
          transport: {
            remote: await this.roomClient.getSendTransportRemoteStats(),
            local: await this.roomClient.getSendTransportLocalStats()
          },
          producer: {
            remote: await this.roomClient.getProducerRemoteStats(this.producerId),
            local: await this.roomClient.getProducerLocalStats(this.producerId)
          },
          dataProducer: {
            remote: await this.roomClient.getDataProducerRemoteStats()
          }
        };
      } else {
        this.stats = {
          consumer: {
            remote: await this.roomClient.getConsumerRemoteStats(this.videoConsumerId || this.audioConsumerId),
            local: await this.roomClient.getConsumerLocalStats(this.videoConsumerId || this.audioConsumerId)
          },
          dataConsumer: {
            remote: await this.roomClient.getDataConsumerRemoteStats(this.dataConsumerId)
          }
        };
      }
    }
  },
  mounted() {
    this.getStats();
    this.timer = setInterval(() => this.getStats(), 2500);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    isMe() {
      this.getStats();
    }
  }
};
</script>

<style scoped>
/* 添加必要的样式 */
</style>