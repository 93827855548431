<template>
    <div class="stats-parser">
      <div v-for="(value, key) in data" :key="key" class="stat-item">
        <span class="stat-key">{{ key }}:</span>
        <span class="stat-value">
          <template v-if="typeof value === 'object'">
            <pre>{{ JSON.stringify(value, null, 2) }}</pre>
          </template>
          <template v-else>
            {{ value }}
          </template>
        </span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'StatsParser',
    props: {
      data: {
        type: Object,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .stats-parser {
    font-family: monospace;
    font-size: 12px;
  }
  .stat-item {
    margin-bottom: 5px;
  }
  .stat-key {
    font-weight: bold;
    margin-right: 5px;
  }
  .stat-value pre {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  </style>