import Vue from 'vue'
import App from './App.vue'  // 确保这里的 'App.vue' 大小写与实际文件名一致
import { Button, Field, Cell, CellGroup, Icon, Collapse, CollapseItem, Tag, NavBar } from 'vant'
import 'vant/lib/index.css'

Vue.use(Button)
Vue.use(Field)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Icon)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Tag)
Vue.use(NavBar)


Vue.use(Vant)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
