export default class Logger {
    constructor(prefix) {
      this.prefix = prefix;
    }
  
    debug(...args) {
      console.log(`[${this.prefix}] DEBUG:`, ...args);
    }
  
    warn(...args) {
      console.warn(`[${this.prefix}] WARN:`, ...args);
    }
  
    error(...args) {
      console.error(`[${this.prefix}] ERROR:`, ...args);
    }
  }