import * as mediasoupClient from 'mediasoup-client';
import { EventEmitter } from 'events';
import { getProtooUrl } from './urlFactory';
import Logger from './Logger';
import { Toast } from 'vant';

const logger = new Logger('RoomClient');

const PC_PROPRIETARY_CONSTRAINTS =
{
  optional : [ { googDscp: true } ]
};

class RoomClient extends EventEmitter
{
  constructor({ roomId, peerId, displayName, device, handlerName, forceTcp, produce, consume, datachannel, forceVP8, forceH264, forceVP9, enableWebcamLayers, enableSharingLayers, webcamScalabilityMode, sharingScalabilityMode, numSimulcastStreams, externalVideo, e2eKey, consumerReplicas })
  {
    super();

    this._closed = false;
    this._displayName = displayName;
    this._device = device;
    this._handlerName = handlerName;
    this._forceTcp = forceTcp;
    this._produce = produce;
    this._consume = consume;
    this._datachannel = datachannel;
    this._forceVP8 = forceVP8;
    this._forceH264 = forceH264;
    this._forceVP9 = forceVP9;
    this._enableWebcamLayers = enableWebcamLayers;
    this._enableSharingLayers = enableSharingLayers;
    this._webcamScalabilityMode = webcamScalabilityMode;
    this._sharingScalabilityMode = sharingScalabilityMode;
    this._numSimulcastStreams = numSimulcastStreams;
    this._externalVideo = externalVideo;
    this._e2eKey = e2eKey;
    this._consumerReplicas = consumerReplicas;

    this._protoo = null;
    this._mediasoupDevice = null;
    this._sendTransport = null;
    this._recvTransport = null;
    this._micProducer = null;
    this._webcamProducer = null;
    this._shareProducer = null;
    this._chatDataProducer = null;
    this._botDataProducer = null;
    this._consumers = new Map();
    this._dataConsumers = new Map();

    this._webcams = new Map();
    this._webcam = {
      device : null,
      resolution : 'hd'
    };

    this._join({ roomId, peerId });
  }

  close()
  {
    if (this._closed)
      return;

    this._closed = true;

    logger.debug('close()');

    // Close protoo Peer
    this._protoo.close();

    // Close mediasoup Transports.
    if (this._sendTransport)
      this._sendTransport.close();

    if (this._recvTransport)
      this._recvTransport.close();

    Toast.success('Room closed');
    this.emit('close');
  }

  async join({ roomId, peerId })
  {
    const protooUrl = getProtooUrl({ roomId, peerId });

    this._protoo = new Protoo(protooUrl);

    this._protoo.on('open', () => this._joinRoom());

    this._protoo.on('failed', () => {
      Toast.fail('WebSocket connection failed');
      logger.error('WebSocket connection failed');
    });

    this._protoo.on('disconnected', () => {
      Toast.fail('WebSocket disconnected');
      logger.error('WebSocket disconnected');

      // Close mediasoup Transports.
      if (this._sendTransport)
      {
        this._sendTransport.close();
        this._sendTransport = null;
      }

      if (this._recvTransport)
      {
        this._recvTransport.close();
        this._recvTransport = null;
      }

      this.emit('disconnected');
    });

    this._protoo.on('close', () => {
      if (this._closed)
        return;

      this.close();
    });

    this._protoo.on('notification', (notification) => {
      logger.debug(
        'proto "notification" event [method:%s, data:%o]',
        notification.method,
        notification.data);

      switch (notification.method)
      {
        case 'newPeer':
          {
            const peer = notification.data;
            this.emit('newPeer', { peer });
          }
          break;

        case 'peerClosed':
          {
            const { peerId } = notification.data;
            this.emit('peerClosed', { peerId });
          }
          break;

        default:
          logger.error(
            'unknown protoo notification.method "%s"',
            notification.method);
      }
    });
  }

  async enableMic()
  {
    logger.debug('enableMic()');

    if (this._micProducer)
      return;


    if (!this._mediasoupDevice.canProduce('audio'))
    {
      logger.error('enableMic() | cannot produce audio');
      return;
    }

    let track;

    try
    {
      track = await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => stream.getAudioTracks()[0]);

      this._micProducer = await this._sendTransport.produce(
        {
          track,
          codecOptions :
          {
            opusStereo : 1,
            opusDtx    : 1
          }
        });


      this._micProducer.on('transportclose', () => {
        this._micProducer = null;
      });

      this._micProducer.on('trackended', () => {
        Toast.fail('Microphone disconnected!');
        this.disableMic().catch(() => {});
      });

      Toast.success('Microphone enabled');
      this.emit('micProducer', { micProducer: this._micProducer });
    }
    catch (error)
    {
      logger.error('enableMic() | failed:%o', error);
      Toast.fail(`Error enabling microphone: ${error.toString()}`);

      if (track)
        track.stop();
    }
  }

  async disableMic()
  {
    logger.debug('disableMic()');

    if (!this._micProducer)
      return;

    this._micProducer.close();

    try
    {
      await this._protoo.request(
        'closeProducer', { producerId: this._micProducer.id });
    }
    catch (error)
    {
      logger.error('disableMic() | failed:%o', error);
    }

    this._micProducer = null;
    Toast.success('Microphone disabled');
  }

  async enableWebcam()
  {
    logger.debug('enableWebcam()');

    if (this._webcamProducer)
      return;

    if (!this._mediasoupDevice.canProduce('video'))
    {
      logger.error('enableWebcam() | cannot produce video');

      return;
    }

    let track;

    try
    {
      const deviceId = await this._getWebcamDeviceId();

      const device = this._webcams.get(deviceId);

      if (!device)
        throw new Error('no webcam devices');

      const { resolution } = this._webcam;

      track = await navigator.mediaDevices.getUserMedia(
        {
          video :
          {
            deviceId : { ideal: deviceId },
            ...VIDEO_CONSTRAINS[resolution]
          }
        });

      const encodings = [];

      if (this._enableWebcamLayers)
      {
        encodings.push({ scaleResolutionDownBy: 4, maxBitrate: 500000 });
        encodings.push({ scaleResolutionDownBy: 2, maxBitrate: 1000000 });
        encodings.push({ scaleResolutionDownBy: 1, maxBitrate: 5000000 });
      }

      this._webcamProducer = await this._sendTransport.produce(
        {
          track,
          encodings,
          codecOptions :
          {
            videoGoogleStartBitrate : 1000
          },
          appData :
          {
            info : 'webcam'
          }
        });


      this._webcamProducer.on('transportclose', () => {
        this._webcamProducer = null;
      });

      this._webcamProducer.on('trackended', () => {
        this.disableWebcam()
          .catch(() => {});
      });

      this.emit('webcamProducer', { webcamProducer: this._webcamProducer });
    }
    catch (error)
    {
      logger.error('enableWebcam() | failed:%o', error);

      if (track)
        track.stop();
    }
  }

  async disableWebcam()
  {
    logger.debug('disableWebcam()');

    if (!this._webcamProducer)
      return;

    this._webcamProducer.close();

    try
    {
      await this._protoo.request(
        'closeProducer', { producerId: this._webcamProducer.id });
    }
    catch (error)
    {
      logger.error('disableWebcam() | failed:%o', error);
    }

    this._webcamProducer = null;
  }

  async _joinRoom()
  {
    logger.debug('_joinRoom()');

    try
    {
      this._mediasoupDevice = new mediasoupClient.Device(
        {
          handlerName : this._handlerName
        });

      const routerRtpCapabilities =
        await this._protoo.request('getRouterRtpCapabilities');

      await this._mediasoupDevice.load({ routerRtpCapabilities });

      // Create mediasoup Transport for sending (unless we don't want to produce).
      if (this._produce)
      {
        const transportInfo = await this._protoo.request(
          'createWebRtcTransport',
          {
            forceTcp         : this._forceTcp,
            producing        : true,
            consuming        : false,
            sctpCapabilities : this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined
          });

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters
        } = transportInfo;

        this._sendTransport = this._mediasoupDevice.createSendTransport(
          {
            id,
            iceParameters,
            iceCandidates,
            dtlsParameters,
            sctpParameters,
            iceServers             : [],
            proprietaryConstraints : PC_PROPRIETARY_CONSTRAINTS
          });

        this._sendTransport.on(
          'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
          {
            this._protoo.request(
              'connectWebRtcTransport',
              {
                transportId : this._sendTransport.id,
                dtlsParameters
              })
              .then(callback)
              .catch(errback);
          });

        this._sendTransport.on(
          'produce', async ({ kind, rtpParameters, appData }, callback, errback) =>
          {
            try
            {
              const { id } = await this._protoo.request(
                'produce',
                {
                  transportId : this._sendTransport.id,
                  kind,
                  rtpParameters,
                  appData
                });

              callback({ id });
            }
            catch (error)
            {
              errback(error);
            }
          });

        this._sendTransport.on(
          'producedata', async (
            {
              sctpStreamParameters,
              label,
              protocol,
              appData
            },
            callback,
            errback
          ) =>
          {
            logger.debug(
              '"producedata" event: [sctpStreamParameters:%o, appData:%o]',
              sctpStreamParameters, appData);

            try
            {
              const { id } = await this._protoo.request(
                'produceData',
                {
                  transportId : this._sendTransport.id,
                  sctpStreamParameters,
                  label,
                  protocol,
                  appData
                });

              callback({ id });
            }
            catch (error)
            {
              errback(error);
            }
          });
      }

      // Create mediasoup Transport for receiving (unless we don't want to consume).
      if (this._consume)
      {
        const transportInfo = await this._protoo.request(
          'createWebRtcTransport',
          {
            forceTcp         : this._forceTcp,
            producing        : false,
            consuming        : true,
            sctpCapabilities : this._useDataChannel
              ? this._mediasoupDevice.sctpCapabilities
              : undefined
          });

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters
        } = transportInfo;

        this._recvTransport = this._mediasoupDevice.createRecvTransport(
          {
            id,
            iceParameters,
            iceCandidates,
            dtlsParameters,
            sctpParameters,
            iceServers : []
          });

        this._recvTransport.on(
          'connect', ({ dtlsParameters }, callback, errback) => // eslint-disable-line no-shadow
          {
            this._protoo.request(
              'connectWebRtcTransport',
              {
                transportId : this._recvTransport.id,
                dtlsParameters
              })
              .then(callback)
              .catch(errback);
          });
      }

      // Join now into the room.
      // NOTE: Don't send our RTP capabilities if we don't want to consume.
      const { peers } = await this._protoo.request(
        'join',
        {
          displayName     : this._displayName,
          device          : this._device,
          rtpCapabilities : this._consume
            ? this._mediasoupDevice.rtpCapabilities
            : undefined,
          sctpCapabilities : this._useDataChannel && this._consume
            ? this._mediasoupDevice.sctpCapabilities
            : undefined
        });

      this.emit('roomJoined', { peers });

      // Enable mic/webcam.
      if (this._produce)
      {
        // Set our media capabilities.
        this.emit('localMediaCapabilities', {
          canSendMic    : this._mediasoupDevice.canProduce('audio'),
          canSendWebcam : this._mediasoupDevice.canProduce('video')
        });

        this.enableMic();
        this.enableWebcam();

        this._sendTransport.on('connectionstatechange', (connectionState) =>
        {
          if (connectionState === 'connected')
          {
            this.enableChatDataProducer();
            this.enableBotDataProducer();
          }
        });
      }
    }
    catch (error)
    {
      logger.error('_joinRoom() failed:%o', error);
      Toast.fail(`Failed to join room: ${error.message}`);
    }
  }

  // Add other methods as needed...
}

export default RoomClient;