import * as mediasoupClient from 'mediasoup-client';

export function getProtooUrl({ roomId, peerId, forceH264, forceVP8 })
{
	const hostname = process.env.VUE_APP_HOSTNAME || window.location.hostname;
	const port = process.env.VUE_APP_PROTOO_PORT || 4443;

	const url = `wss://${hostname}:${port}/?roomId=${roomId}&peerId=${peerId}`;

	const urlObject = new URL(url);

	if (forceH264)
		urlObject.searchParams.set('forceH264', 'true');
	else if (forceVP8)
		urlObject.searchParams.set('forceVP8', 'true');

	const handler = mediasoupClient.detectDevice();

	if (handler)
		urlObject.searchParams.set('handlerName', handler.name);

	return urlObject.toString();
}

export function getSocketIoUrl()
{
	const hostname = process.env.VUE_APP_HOSTNAME || window.location.hostname;
	const port = process.env.VUE_APP_SOCKET_IO_PORT || 3000;

	return `wss://${hostname}:${port}`;
}